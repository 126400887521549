<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">템플릿 수정</h2>
      </div>
      <div class="body_section">
        <div class="area_iframe">
          <div id="iframe" />
        </div>
      </div>
    </div>
    <!-- <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoList">목록</button>
      </div>
    </Sticky> -->
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
// import Sticky from '@/components/layout/content/Sticky.vue'
import ApiService from "@/services/ApiService";

import PageMixin from "@/mixins/PageMixin";
// import GlosignMixin from '@/mixins/GlosignMixin'

export default {
  components: {
    PageWithLayout,
    // Sticky,
  },
  mixins: [
    PageMixin,
    // GlosignMixin
  ],
  mounted() {
    // 글로싸인 화면 삽입 영역 + 글로사인 mixin 추가 필요
    // SDK 적용 가이드 - https://developers.glosign.com/sdk/javascript/viewer

    const { templateId, beforePage } = this.$route.params;

    let options = {
      viewerType: "template",
      container: "iframe",
      templateId: templateId,
      clientId: "u1ef6700fa77e513e1b0bbd475e03d7ad", // clientId 고정값.
    };
    let viewer = new glosapi.viewer("y16X4tFyJNvScb2JS8KXchAm6").setOptions(options);

    viewer.open();

    viewer.setCallback((result) => {
      if (result.action == "complete") {
        this.saveTemplate(result);
      } else if (result.action == "close") {
        if (beforePage == "TemplateList") {
          this.goToList();
        }
      }
    });
  },
  methods: {
    onClickGoList() {
      this.goToList();
    },
    async saveTemplate(res) {
      const url = `${this.$apiPath.TEMPLATE}/update`;

      const obj = this.makeParamsForSave(res);

      const result = await ApiService.shared.putData(url, obj);

      if (result.code !== "200") {
        this.alert(result.text);

        return;
      }

      this.goToList(res);
    },
    makeParamsForSave(data) {
      let obj = {
        templateId: data.result.template_id,
        templateType: data.type,
        templateGubun: "",
      };

      return obj;
    },
    goToList(obj) {
      this.$router.push({
        path: this.$routerPath.SYSTEM_TEMPLATE_LIST,
      });
      if (obj) this.alert("템플릿 수정이 완료되었습니다.");
    },
  },
};
</script>
